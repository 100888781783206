export const UNASSIGNED = 'Unassigned';
export const INPROGRESS = 'Inprogress';
export const COMPLETED = 'Completed';
export const ALL_TICKETS = 'all';
export const MY_TICKETS = 'self';
export const REPORTS = 'reports';

export const msgStatus = {
  [UNASSIGNED]: 1,
  [INPROGRESS]: 2,
  [COMPLETED]: 3,
  1: UNASSIGNED,
  2: INPROGRESS,
  3: COMPLETED,
};

export const getStatusCode = (status) => msgStatus[status];

export const userTicketList = [
  { label: 'All Tickets', optValue: ALL_TICKETS },
  { label: 'My Tickets', optValue: MY_TICKETS },
  { label: 'Reports', optValue: REPORTS }
];
