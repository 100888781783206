import React, { useState } from 'react';
import { Autocomplete, Box, TextField, Chip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { changeAgentSettings } from 'redux/channels/channels';
import { updateUsers } from 'redux/settings/settings';
import Save from 'components/reusables/save/Save';

import styles from './Settings.module.scss';
import CustomAvatar from 'components/reusables/avatar/CustomAvatar';

const AddUsers = ({
    settings = {},
    channelId,
    onSuccess,
    onFailure,
    onCancel }) => {

    const userLimit = 50;
    const limitError = 'Only ' + userLimit + ' users can be added at a time';
    const dispatch = useDispatch();
    const agentEmails = new Set(settings?.agents?.map(a => a.email) || []);
    const allUsersEmails = new Set(settings?.channelUsers?.map(a => a.email) || []);
    const userMap = settings?.channelUsers.reduce((map, agent) => { map[agent.email] = agent; return map; }, {});
    const userOptions = [...allUsersEmails].filter(x => !agentEmails.has(x));
    const [agents, setAgents] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [error, setError] = useState();

    const sendReq = async (action, params) => {
        await action(params)
            .then((res) => {
                onSuccess("Users added successfully");
                if (res) {
                    dispatch(changeAgentSettings({ agents: res }));
                }
            }).catch((err) => {
                const msg = err?.response?.data?.error;
                const context = msg?.details?.[0]?.context;
                context?.limit ? setError(limitError) : context?.value ? setInvalidEmailError(context.value) : setError(msg);
                setAgents(params.users.map(u => u.email));
            });
    }
    const isInValidEmail = (email) => {
        return !(/\S+@\S+\.\S+/.test(email));
    }
    const buildAgent = (v) => {
        return (typeof v === 'string' || v instanceof String) ? { email: v, is_admin: false } : v;
    }
    const handleTextFieldValue = () => {
        const email = inputValue;
        let users = agents;
        if (email.length <= 0) return users;
        if (!isInValidEmail(email)) {
            users = users.concat(email);
            setAgents(users);
            setInputValue('');
        }
        else setInvalidEmailError(email);
        return users;
    }
    const saveAdminUsers = async (event) => {
        event.preventDefault();
        const users = handleTextFieldValue();
        if (users.length === 0) return;
        await sendReq(updateUsers, {
            channelId: channelId,
            users: users.map(a => buildAgent(a))
        });
    };
    const handleOnchange = (event, values) => {
        if (values.length > userLimit) { setError(limitError); values.splice(values.length - 1, 1); return; }
        const ids = values.filter(v => isInValidEmail(v));
        ids.length === 0 ? setError() : setInvalidEmailError(ids[ids.length - 1]);
        setAgents(values);
    };
    const handleOnInputChange = (event, newInputValue) => {
        const options = newInputValue.split(",");
        if (options.length > 1) {
            const validEmails = options.filter(x => !isInValidEmail(x) && !agents.includes(x));
            const inValidEmails = options.filter(x => isInValidEmail(x) || agents.includes(x));
            setAgents(agents.concat(validEmails))
            setInputValue(inValidEmails.join(","));
        } else {
            setInputValue(newInputValue);
        }
    };
    const handleKeyDown = (e) => {
        if ((e.key === 'Enter' || e.key === ',') && e.target.value) {
            const isInvalid = isInValidEmail(e.target.value);
            e.defaultMuiPrevented = isInvalid;
            isInvalid ? setInvalidEmailError(e.target.value) : setError();
        }
    }
    const setInvalidEmailError = (email) => {
        setError('Invalid email address: ' + email)
    }

    return (
        <div className={styles.addUsersContainer}>
            <Autocomplete
                multiple
                freeSolo
                id="add-users"
                options={userOptions}
                value={agents}
                inputValue={inputValue}
                filterSelectedOptions
                isOptionEqualToValue={(option, value) => option === value}
                renderTags={(value, getTagProps) => value.map((option, index) => (
                    <div className={styles.userTag}>
                    <Chip  {...getTagProps({ index })} label={option}
                        avatar={<CustomAvatar styles={{ size: 25, margin: '5px' }} name={option} url={userMap[option]?.image_url} />}>
                    </Chip>
                    </div>
                ))
                }
                renderOption={(props, option) => 
                    <Box {...props} component="li" >
                        <div className={styles.userOption} >
                            <CustomAvatar styles={{ marginRight: '10px' }} name={option} url={userMap[option]?.image_url} />
                            <div className={styles.userOptionText}>{option}</div>
                        </div>
                    </Box>}
                onChange={handleOnchange}
                onInputChange={handleOnInputChange}
                onKeyDown={handleKeyDown}
                renderInput={params => (
                    <TextField
                        key={'email-input'}
                        type='email'
                        {...params}
                        label={'Add Agents'}
                        placeholder={'Agents'}
                        error={error !== undefined}
                        helperText={error}
                    />
                )}
            />
            <Save saveChanges={saveAdminUsers} cancelChanges={onCancel}></Save>
        </div>)
};
export default AddUsers;