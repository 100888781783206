//libs
import React from 'react';
//styles
import styles from './Dropdown.module.scss';

const Dropdown = ({ value, onChange, options = [] }) => {
  const handleSelectChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={styles.container}>
      <select
        className={styles.dropdown}
        value={value}
        onChange={handleSelectChange}
      >
        {options.map(({ label, optValue }) => {
          return (
            <option key={optValue} value={optValue}>
              {label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Dropdown;
