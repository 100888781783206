//libs
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
//styles
import styles from './App.module.scss';
//pages
import Landing from 'pages/landing/Landing';
import Channels from 'pages/channels/Channels';
//components
import NavBar from 'components/containers/navBar/NavBar';
import ProtectedRoute from 'components/reusables/protectedRoute/ProtectedRoute';
import UserBar from 'components/containers/userBar/UserBar';
//actions
import { fetchSlackChannels } from 'redux/appConfig/appConfig';
//selectors
import { selectUserInfo, selectSlackChannels } from 'redux/appConfig/appConfig';
//utilities
import { history } from 'utilities/routes/history';
import { routes } from 'utilities/routes/routePath';
import { fetchEmojisForChannel } from 'redux/channels/channels';

const App = () => {
  const dispatch = useDispatch();
  const { tokenId } = useSelector(selectUserInfo);
  const slackChannels = useSelector(selectSlackChannels);

  useEffect(() => {
    if (tokenId && !slackChannels.length) {
      dispatch(fetchSlackChannels());
      dispatch(fetchEmojisForChannel());
    }
    if (tokenId && slackChannels.length) {
      const [firstChannel] = slackChannels;
      history.push(routes.gotoChannelsPage(firstChannel.id));
    }
  }, [tokenId, slackChannels, dispatch]);

  return (
    <div className={styles.container}>
      <Switch>
        <Route exact path={routes.landingPage}>
          <Landing />
        </Route>
        <Route exact path={routes.channelsPage}>
          <ProtectedRoute
            isAuthenticated={tokenId}
            redirectTo={routes.landingPage}>
            <div className={styles.leftContainer}>
              <NavBar />
            </div>
            <div className={styles.rightContainer}>
              <div className={styles.rightTopCon}>
                <UserBar />
              </div>
              <div className={styles.rightBottomCon}>
                <Channels />
              </div>
            </div>
          </ProtectedRoute>
        </Route>
        <Route path="*">
          <Redirect to={routes.landingPage} />
        </Route>
      </Switch>
    </div>
  );
};

export default App;
