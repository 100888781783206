//libs
import React from 'react';
//styles
import styles from './DatePicker.module.scss';

const DatePicker = ({ value, onChange }) => {
  const date = new Date();
  const today = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}`;

  const handleDateChange = (e) => {
    onChange(e.target.value, e);
  };

  return (
    <>
      <input
        className={styles.datePicker}
        type="date"
        value={value}
        onChange={handleDateChange}
        max={today}
      />
    </>
  );
};

export default DatePicker;
