import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from 'utilities/network/axios';

const DEFAULT_METRICS_VALUE = '-';

const initialState = {
  unAssigned: DEFAULT_METRICS_VALUE,
  inProgress: DEFAULT_METRICS_VALUE,
  completed: DEFAULT_METRICS_VALUE,
  avgCompletionTime: DEFAULT_METRICS_VALUE,
  avgFirstResponseTime: DEFAULT_METRICS_VALUE,
};

//async actions
export const fetchMetricsForChannel = createAsyncThunk(
  'metrics/fetchMetricsForChannel',
  async ({ channelId, startDate, endDate, user }) => {
    const { data: metricsInfo } = await axios.get('/slackdesk/metrics', {
      params: { channelId, startDate, endDate, user },
    });
    return metricsInfo;
  }
);

//slice configuration
export const metrics = createSlice({
  name: 'metrics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetricsForChannel.pending, (state) => {
        state.unAssigned = DEFAULT_METRICS_VALUE;
        state.inProgress = DEFAULT_METRICS_VALUE;
        state.completed = DEFAULT_METRICS_VALUE;
        state.avgCompletionTime = DEFAULT_METRICS_VALUE;
        state.avgFirstResponseTime = DEFAULT_METRICS_VALUE;
        state.rating = DEFAULT_METRICS_VALUE;
        state.reviews = 0;
      })
      .addCase(fetchMetricsForChannel.fulfilled, (state, action) => {
        state.unAssigned = action.payload.unAssigned;
        state.inProgress = action.payload.inProgress;
        state.completed = action.payload.completed;
        state.avgCompletionTime = action.payload.avgCompletionTime;
        state.avgFirstResponseTime = action.payload.avgFirstResponseTime;
        state.rating = action.payload.rating;
        state.reviews = action.payload.reviews;
      });
  },
});

// state selectors
export const selectUserChannelMetrics = (state) => {
  return state.metrics;
};

export default metrics.reducer;
