//libs
import CustomPopover from 'components/customPopover/CustomPopover';
import React, { useState } from 'react';
import Avatar from '../avatar/CustomAvatar';
import EmojiPicker from '../emojiPicker/EmojiPicker';
//styles
import styles from './EmojiInput.module.scss';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

const EmojiInput = ({ defaultEmoji, onChange, className, usedEmojis, required = true }) => {

    const [selectedEmoji, setSelectedEmoji] = useState(defaultEmoji);
    return (
        <div className={className}>
            <CustomPopover
                renderPopover={(handleClose) => {
                    return (<><EmojiPicker usedEmojis={usedEmojis} onSelect={(event, emoji) => { setSelectedEmoji(emoji); handleClose(); onChange(event, emoji) }} /></>)
                }}
                renderTrigger={() => <>
                    <div className={styles.emojiInputContainer}>
                        {selectedEmoji && selectedEmoji.name && <Avatar style={{size: 35}} {...selectedEmoji} />}
                    </div>
                    <div className={styles.emojiContainer}>
                        <input name='selectedEmoji' onChange={()=>{}} required={required} value={selectedEmoji?.name} />
                        <SentimentSatisfiedAltIcon sx={{ fontSize: '17px' }} />
                    </div>
                </>} />
        </div>
    );
};

export default EmojiInput;
