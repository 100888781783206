import emojiData from './emoji.json'

const defaultEmojis = emojiData.sort((a, b) => a.sort_order - b.sort_order);
const allEmojis = defaultEmojis.map(e => {
    return {
        name: e.short_name,
        full_name: e.name.toLowerCase(),
        unified: e.unified,
        category: e.category,
        sort_order: e.sort_order
    }
});
export const emojiTypes = [
    { type: 'Smileys & Emotion', name: 'grinning' },
    { type: 'People & Body', name: 'wave' },
    { type: 'Animals & Nature', name: 'turtle' },
    { type: 'Food & Drink', name: 'tangerine' },
    { type: 'Travel & Places', name: 'blue_car' },
    { type: 'Activities', name: 'baseball' },
    { type: 'Objects', name: 'tv' },
    { type: 'Symbols', name: 'peace_symbol' },
    { type: 'Flags', name: 'triangular_flag_on_post' },
    { type: 'Custom', name: 'slack', url: 'https://emoji.slack-edge.com/T02V62HHPA4/slack/7d462d2443.png' },
];

export const ticketStates = 'ticketStates';
export const categories = 'categories';
export const ratings = 'ratings';

export const getAllEmojis = (channelEmojis = {}) => {
    return allEmojis.concat(Object.values(channelEmojis));
}