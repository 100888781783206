import React, { useState } from 'react';
import { ListItemAvatar, ListItemText, Switch, List, ListItem, ListItemSecondaryAction, ThemeProvider, createTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { deleteUser, updateUsers } from 'redux/settings/settings';
import { changeAgentSettings } from 'redux/channels/channels';
import { useDispatch } from 'react-redux';
import CustomAvatar from 'components/reusables/avatar/CustomAvatar';

import styles from './Settings.module.scss';
import SearchBar from 'components/reusables/searchBar/SearchBar';

const UserSettings = ({
    channelId,
    isAdmin,
    onSuccess,
    onFailure,
    agents = [],
    label = "" }) => {

    const theme = createTheme({
        components: {
            MuiListItemText: {
                styleOverrides: {
                    primary: {
                        fontSize: '1.4rem',
                        fontFamily: 'Inter, sans-serif',
                        textTransform: 'capitalize'
                    },
                    secondary: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '1.2rem',
                        textTransform: 'none'
                    }
                },
            }
        }
    });
    const dispatch = useDispatch();
    // const settings = useSelector(selectChannelSettings);
    const [filteredAgents, setFilteredAgents] = useState(agents);

    const sendReq = async (action, params, successMessage, errorMessage) => {
        await action(params)
            .then((res) => {
                onSuccess(successMessage);
                if (res) {
                    dispatch(changeAgentSettings({ agents: res }));
                }
            }).catch((err) => {
                onFailure(errorMessage)
            });

    }
    const saveAdminUsers = async (draftUsers) => {
        await sendReq(updateUsers, {
            channelId: channelId,
            users: draftUsers
        }, "Updated Admin successfully", "Failed to update agent");
    };

    function capitalize(string) {
        return string.split(" ").map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(" ");;
    }

    const removeUser = async (agent) => {
        if (window.confirm("Are you sure want to remove " + capitalize(agent.name) + " from this channel?")) {
            await sendReq(deleteUser, {
                channelId: channelId,
                user: { email: agent.email }
            }, "Removed agent successfully", "Failed to remove agent");
        }
    };

    return (
        <div className={styles.userContainer} is-admin={'' + isAdmin}>
            {agents.length > 5 && isAdmin && <div className={styles.userSearchContainer}>
                        <SearchBar debounceTimeInMs={100} className={styles.searchBar} placeholder="Search agent"
                            onChange={(value) => {
                                let key = value.toLowerCase();
                                value === '' ? setFilteredAgents(agents)
                                    : setFilteredAgents(agents.filter(agent => agent.email.toLowerCase().startsWith(key) || agent.name.toLowerCase().includes(key)));
                            }} />
                    </div>}
            <ThemeProvider theme={theme}>
                <List className={styles.userListContainer}>
                    {filteredAgents.map((agent, index) => {
                        return (
                            <ListItem key={agent.email}>
                                <ListItemAvatar>
                                    <CustomAvatar name={agent.name} url={agent.image_url} />
                                </ListItemAvatar>
                                <ListItemText id={agent.email} primary={agent.name} secondary={agent.email} />
                                {isAdmin && <ListItemSecondaryAction className={styles.adminActions}>
                                    <Switch
                                        edge="end"
                                        checked={agent.is_admin}
                                        onClick={(event) => saveAdminUsers([{ ...agent, is_admin: event.target.checked }])}
                                    />
                                    <ListItemText className={styles.adminLabel} edge="end" id={agent.email} primary={agent.is_admin ? 'Admin' : 'Agent'} />
                                    <div onClick={() => removeUser(agent, index)}>
                                        <CloseIcon className={styles.removeUser} edge="end" />
                                    </div>
                                </ListItemSecondaryAction>}
                            </ListItem>
                        )
                    })}
                </List>
            </ThemeProvider>
        </div>)
};
export default UserSettings;