import React, { useRef, useState } from 'react';
import { ListItemText, List, ListItem, ListItemSecondaryAction, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { updateCategories } from 'redux/settings/settings';
import { changeCategorySettings } from 'redux/channels/channels';
import { useDispatch } from 'react-redux';
import Save from 'components/reusables/save/Save';
import EmojiInput from 'components/reusables/emojiInput/emojiInput';
import styles from './Settings.module.scss';
import SearchBar from 'components/reusables/searchBar/SearchBar';

const CategorySettings = ({
    channelId,
    onSuccess,
    onFailure,
    onCancel,
    elements,
    usedEmojis = [],
}) => {

    const categoryTemplate = { name: '', emoji_id: '' };
    const dispatch = useDispatch();
    const categoriesEndRef = useRef();
    const [draftCategories, setDraftCategories] = useState(JSON.parse(JSON.stringify(elements)));
    const [filterText, setFilterText] = useState('');
    const [emojis, setEmojis] = useState(usedEmojis);

    const scrollToBottom = () => {
        categoriesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }

    const sendReq = async (action, params, successMessage, errorMessage) => {
        await action(params)
            .then((res) => {
                onSuccess(successMessage);
                if (res) {
                    dispatch(changeCategorySettings({ categories: res }));
                }
            }).catch((err) => {
                onFailure(errorMessage + (err.response.data.error.details?.[0]?.message || ''))
            });
    }

    const saveCategories = async (event) => {
        event.preventDefault();
        if (draftCategories.find(c => c.error)) return;
        await sendReq(updateCategories, {
            channelId: channelId,
            categories: draftCategories.map(u => { return { name: u.name, emoji_id: u.emoji_id || u.name, id: u.id } })
        }, "Updated categories successfully", "Failed to update categories, Please try again");
    };
    const addCategory = () => {
        setTimeout(() => scrollToBottom(), 100);
        setDraftCategories(draftCategories.concat(categoryTemplate));
        setFilterText('');
    }
    const removeCategory = (index) => {
        const cs = [].concat(draftCategories);
        const removed = cs.splice(index, 1);
        emojis.splice(emojis.indexOf(removed.emoji_id), 1);
        setEmojis(emojis);
        setDraftCategories(cs);
        setFilterText('');
    }
    const filterCategories = () => {
        return draftCategories.map(c => { c.hidden = !c.name.toLowerCase().includes(filterText.toLowerCase()); return c; });
    }
    return (
        <form onSubmit={saveCategories}>
            <div className={styles.elementsContainer}>
                {draftCategories?.length > 5 && <div className={styles.userSearchContainer}>
                    <SearchBar debounceTimeInMs={100} className={styles.searchBar} placeholder="Search category"
                        onChange={(value) => setFilterText(value)} />
                </div>}
                <List className={styles.categoriesContainer}>
                    {filterCategories().map((category, index) =>
                        category.hidden ? <></> : <ListItem className={styles.categoryContainer} key={index + category.name}>
                            <ListItemText id={category.email}>
                                <TextField type='text' required error={category?.error?.length > 0} helperText={category.error} className={styles.categoryName} defaultValue={category.name} variant="standard"
                                    InputProps={{ readOnly: category.readOnly, defaultValue: category.name }}
                                    onBlur={(e) => {
                                        if (category.readOnly) return;
                                        const names = draftCategories.filter((c, i) => c.emoji_id !== category.emoji_id).map(c => c.name);
                                        const isDuplicate = e.target.value !== '' && names.indexOf(e.target.value) !== -1;
                                        category.name = e.target.value;
                                        category.error = isDuplicate ? 'Already present' : undefined;
                                        setDraftCategories(JSON.parse(JSON.stringify(draftCategories)));
                                    }}
                                />
                            </ListItemText>
                            <ListItemSecondaryAction className={styles.adminActions}>
                                <EmojiInput className={styles.categoryEmoji} defaultEmoji={{ name: category.emoji_id }} usedEmojis={emojis}
                                    onChange={(event, value) => {
                                        category.name = category.name === '' ? value.name : category.name;
                                        category.emoji_id = value.name;
                                        setEmojis(emojis.concat(value.name));
                                    }}
                                />
                                {!category.readOnly ? <CloseIcon className={styles.removeCategory} onClick={() => removeCategory(index)} edge="end" /> : <div style={{ width: '20px' }}></div>}
                            </ListItemSecondaryAction>
                        </ListItem>)}
                    <div ref={categoriesEndRef} />
                </List>
                <div className={styles.addCategory} onClick={addCategory}><AddCircleIcon /> Add Category</div>
            </div>
            <Save type='submit' cancelChanges={onCancel}></Save>
        </form>
    );
};
export default CategorySettings;