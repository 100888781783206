import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { Box, Button, Tab, Tabs, Tooltip, CircularProgress } from '@mui/material';
import styles from './Settings.module.scss';
import UserSettings from './UserSettings';
import CategorySettings from './categorySettings';
import JiraSettings from './JiraSettings';
import { changeEmojiSettings, changeRatingSettings } from 'redux/channels/channels';
import EmojiSettings from './emojiSettings';
import { updateRatings, updateTicketStates } from 'redux/settings/settings';
import EditableComponent from 'components/reusables/editableComponent/EditableComponent';
import AddUsers from './AddUsers';
import { ratings, ticketStates } from 'utilities/constants/emojis';
import ExportCSVParams from './ExportCSVParams';

const Settings = ({ settings, setOpenSettings, channelId, profile = {}, jiraProjects }) => {

    const isAdmin = () => {
        const agents = settings?.agents || [];
        const admins = agents?.filter(user => user.is_admin) || [];
        return admins.find((a) => a.email === profile?.profileObj?.email) || !admins[0];
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <>{children}</>
                )}
            </div>
        );
    }
    const tabProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }
    const getEmojiIds = (emojis) => {
        return emojis.map(t => t.emoji_id);
    }
    const getUsedEmojis = (settings) => {
        return getEmojiIds(settings?.ticketStates)
            ?.concat(getEmojiIds(settings?.ratings)
            ?.concat(getEmojiIds(settings?.categories))
            ?.concat(getEmojiIds(settings?.jiraMappings))
                );
    }

    return (
        <div className={styles.container}>
            <div className={styles.close}>
                <Tooltip title="Back" sx={{ fontSize: 14 }}>
                    <Button onClick={() => setOpenSettings(false)} >
                        <ArrowBackIcon sx={{ fontSize: 16 }} ></ArrowBackIcon>
                        Back
                    </Button>
                </Tooltip>
            </div>
            <div >
                <Box sx={{ flexGrow: 1, bgcolor: 'background.paper' }} >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        className={styles.settingsTabs}>
                        <Tab classes={{ root: styles.settingsTab }} label="Agents" {...tabProps(0)} />
                        <Tab classes={{ root: styles.settingsTab }} label="Emojis" {...tabProps(1)} />
                        <Tab classes={{ root: styles.settingsTab }} label="Categories" {...tabProps(2)} />
                        <Tab classes={{ root: styles.settingsTab }} label="Ratings" {...tabProps(3)} />
                        <Tab classes={{ root: styles.settingsTab }} label="Export CSV" {...tabProps(4)} />
                        <Tab classes={{ root: styles.settingsTab }} label="Jira" {...tabProps(5)} />
                    </Tabs>
                    <TabPanel className={styles.tabPanel} value={value} index={0}>
                        <EditableComponent
                            editable={isAdmin()}
                            label='Add Agents'
                            render={(props) => <UserSettings isAdmin={isAdmin()} agents={settings?.agents} channelId={channelId} {...props} />}
                            renderOnEditMode={(props) =>
                                <AddUsers channelId={channelId} settings={settings} {...props} />
                            }
                        />
                    </TabPanel>
                    <TabPanel className={styles.tabPanel} value={value} index={1}>
                        <EditableComponent
                            editable={isAdmin()}
                            label='Edit Emojis'
                            render={(props) => <EmojiSettings elements={settings?.ticketStates} channelId={channelId} {...props} />}
                            renderOnEditMode={(props) =>
                                <EmojiSettings
                                    elements={settings?.ticketStates} isEditMode={true}
                                    onSave={updateTicketStates} channelId={channelId} label={ticketStates}
                                    updateState={changeEmojiSettings} {...props}
                                    usedEmojis={getUsedEmojis(settings)}
                                />
                            }
                        />
                    </TabPanel>
                    <TabPanel className={styles.tabPanel} value={value} index={2}>
                        <EditableComponent
                            editable={isAdmin()}
                            label='Edit Categories'
                            elements={settings?.categories}
                            render={(props) => <EmojiSettings elements={settings?.categories} channelId={channelId} {...props} />}
                            renderOnEditMode={(props) =>
                                <CategorySettings elements={settings?.categories} channelId={channelId} {...props} usedEmojis={getUsedEmojis(settings)} />
                            }
                        />
                    </TabPanel>
                    <TabPanel className={styles.tabPanel} value={value} index={3}>
                        <EditableComponent
                            editable={isAdmin()}
                            label='Edit Ratings'
                            render={(props) => <EmojiSettings elements={settings?.ratings} channelId={channelId} {...props}
                                columns={[{ name: 'name', styles: { width: '40%' } }, { name: 'value' }]}
                                showHeaders={true} />}
                            renderOnEditMode={(props) =>
                                <EmojiSettings
                                    elements={settings?.ratings} onSave={updateRatings}
                                    isEditMode={true} channelId={channelId} label={ratings}
                                    updateState={changeRatingSettings} {...props}
                                    columns={[{ name: 'name', styles: { width: '25%' } }, { name: 'value' }]}
                                    usedEmojis={getUsedEmojis(settings)}
                                    showHeaders={true} />
                            }
                        />
                    </TabPanel>
                    <TabPanel className={styles.tabPanel} value={value} index={4}>
                        <EditableComponent
                            editable={isAdmin()}
                            label='Select Filters'
                            render={(props) => <></>}
                            renderOnEditMode={(props) =>
                                <ExportCSVParams {...props} />
                            }
                        />
                    </TabPanel>
                    <TabPanel className={styles.tabPanel} value={value} index={5}>
                        <EditableComponent
                            editable={isAdmin()}
                            label='Edit Jira Mappings'
                            elements={settings?.jiraMappings}
                            render={(props) => <EmojiSettings elements={settings?.jiraMappings} channelId={channelId} {...props} 
                            columns={[{ name: 'jira_project_name', styles: { width: '1%' }, label: 'Project' }, { name: 'jira_issuetype_name', label: 'IssueType' }]}
                            showHeaders={true}
                            />}
                            renderOnEditMode={(props)=>
                                {
                                        return <JiraSettings
                                        elements={settings?.jiraMappings} onSave={updateRatings}
                                        jiraProjects={jiraProjects}
                                        settingsProp={settings}
                                        issueType10={[]}
                                        isEditMode={true} channelId={channelId} label={ratings}
                                        updateState={changeRatingSettings} {...props}
                                        columns={[{ name: 'jira_project_name', styles: { width: '25%' }, label: 'Project' }, { name: 'jira_issuetype_name', label: 'IssueType' }]}
                                        usedEmojis={getUsedEmojis(settings)}
                                        showHeaders={true} />
                                    
                                }
                                }
                        />
                    </TabPanel>
                </Box>
            </div>
            <div className={styles.settingsContent}></div>
        </div>
    );
};

export default Settings;
