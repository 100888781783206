import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from 'utilities/network/axios';

const formatDate = (date) => {
  return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
}

const currentDate = new Date();
const presentDate = formatDate(currentDate);
const monthStartDate = formatDate(new Date(currentDate.setDate(currentDate.getDate() - 30)));

const initialState = {
  user: {},
  startDate: monthStartDate,
  endDate: presentDate,
  slackChannels: [],
  loginError: '',
};

//async actions
export const verifyUserLogin = createAsyncThunk(
  'appConfig/verifyUserLogin',
  async (authObj, thunkAPI) => {
    const { status } = await axios.post('/slackdesk/login', authObj);
    return status === 204
      ? authObj
      : thunkAPI.rejectWithValue('Unauthorized user login');
  }
);

export const fetchSlackChannels = createAsyncThunk(
  'appConfig/fetchSlackChannels',
  async () => {
    const { data } = await axios.get('/slackdesk/channels');
    return data.channels;
  }
);

//slice configuration
export const appConfig = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    changeIntervalDate: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    logoutUser: (state) => {
      state.user = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyUserLogin.pending, (state) => {
        state.user = {};
        state.loginError = '';
      })
      .addCase(verifyUserLogin.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(verifyUserLogin.rejected, (state, action) => {
        const userErrMsg = action.error.message.includes('404')
          ? 'User not found'
          : null;
        state.loginError = action.payload ?? userErrMsg;
      })
      .addCase(fetchSlackChannels.fulfilled, (state, action) => {
        if (Array.isArray(action.payload) && action.payload.length) {
          state.slackChannels = action.payload;
        }
      });
  },
});

// state selectors
export const selectUserInfo = (state) => {
  return state.appConfig.user;
};

export const selectLoginErrorMsg = (state) => {
  return state.appConfig.loginError;
};

export const selectSlackChannels = (state) => {
  return state.appConfig.slackChannels;
};

export const selectIntervalDate = (state) => {
  const { startDate, endDate } = state.appConfig;
  return { startDate, endDate };
};

export const { changeIntervalDate, logoutUser } = appConfig.actions;

export default appConfig.reducer;
