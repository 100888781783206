//libs
import React from 'react';
//styles
import styles from './MessageCard.module.scss';
//components
import TagBox from 'components/reusables/tagBox/TagBox';
import RenderView from 'components/reusables/renderView/RenderView';
//assests
// import linkSrc from 'assests/link.png';
import linkSrc from 'assests/icons8-slack-48.png';
import jiraSrc from 'assests/icons8-jira-48.png';
import profilePic from 'assests/profile.png';

const MessageCard = ({ info }) => {
  return (
    <div className={styles.msgCardCon}>
      <div className={styles.nameCon}>
        <div className={styles.leftNameCon}>
          <RenderView renderIfTrue={info.msgCreatorAvatorUrl}>
            <img
              className={styles.userAvator}
              src={info.msgCreatorAvatorUrl}
              alt="-"
              onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = profilePic; }}
            />
          </RenderView>
          <RenderView renderIfTrue={!info.msgCreatorAvatorUrl}>
            <div className={styles.userNameCircle}>
              {info.msgCreatedBy ? info.msgCreatedBy[0] : null}
            </div>
          </RenderView>
          <p className={styles.userName}>
            {info.msgCreatedBy}
            <span className={styles.creationTime}>{info.createdAt}</span>
          </p>
          <a href={info.permaLink} target="_blank" rel="noreferrer">
            <img className={styles.slackLinkIcon} src={linkSrc} alt="-" />
          </a>
          {info.jiraLink && 
          <a href={info.jiraLink} target="_blank" rel="noreferrer">
            <img className={styles.slackLinkIcon} src={jiraSrc} alt="-" />
          </a>
          }
        </div>
        <div className={styles.rightNameCon}>
          {info.rating && <div className={styles.ratingMsg}><TagBox title="Rating: " content={info.rating} /></div>}
          <div className={styles.lastUpdatedCon}>
            <TagBox title="Last updated time: " content={info.updatedAt} />
          </div>
          <TagBox title="Assigned to: " content={info.assigneeName} />
        </div>
      </div>
      <div className={styles.msgCon}>
        <p className={styles.userMsg}>{info.msg}</p>
      </div>
    </div>
  );
};

export default MessageCard;
