import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ListItemText, List, ListItem, ListItemSecondaryAction, TextField, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { updateJiraMappings, getIssueTypeForProject } from 'redux/settings/settings';
import { changeJiraSettings } from 'redux/channels/channels';
import Save from 'components/reusables/save/Save';
import EmojiInput from 'components/reusables/emojiInput/emojiInput';
import styles from './Settings.module.scss';
import SearchBar from 'components/reusables/searchBar/SearchBar';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
const { getAllProjects } = require('../../redux/settings/settings');


const JiraSettings = ({
    channelId,
    onSuccess,
    onFailure,
    onCancel,
    elements,
    // jiraProjects,
    settingsProp,
    usedEmojis = [],
}) => {

    const categoryTemplate = { jira_project_name: '',jira_project_id: '',  emoji_id: '', jira_issuetype_id: '',  jira_issuetype_name: '' };
    const dispatch = useDispatch();
    const categoriesEndRef = useRef();
    const [draftCategories, setDraftCategories] = useState(JSON.parse(JSON.stringify(elements)));
    const [filterText, setFilterText] = useState('');
    const [emojis, setEmojis] = useState(usedEmojis);
    const [issueType1, setIssueType1] = useState([{id: 1, name: 'Loading'}]);
    const [issueTypeProjectMap, setissueTypeProjectMap] = useState({});
    const [useDefaultOptions, setUseDefaultOptions] = useState(true);
    const [jiraProjectHasLoaded, setJiraProjectHasLoaded] = useState(false);
    const [jiraProjects1, setJiraProjects1] = useState(null);

    const scrollToBottom = () => {
        categoriesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }

    const sendReq = async (action, params, successMessage, errorMessage) => {
        await action(params)
            .then((res) => {
                onSuccess(successMessage);
                if (res) {
                    dispatch(changeJiraSettings({ jiraMappings: res }));
                }
            }).catch((err) => {
                onFailure(errorMessage + (err.response.data.error.details?.[0]?.message || ''))
            });
    }

    const saveCategories = async (event) => {
        event.preventDefault();
        if (draftCategories.find(c => c.error)) return;
        const payload = draftCategories.map(c => ({
            id: c.id,
            name: c.name,
            emoji_id: c.emoji_id || c.name,
            jira_project_id: c.jira_project_id,
            jira_project_name: c.jira_project_name,
            jira_issuetype_id: c.jira_issuetype_id,
            jira_issuetype_name: c.jira_issuetype_name
        }));
        await sendReq(updateJiraMappings, {
            channelId: channelId,
            jiraMappings: payload
        }, "Updated Jira Mappings successfully", "Failed to update Jira Mappings, Please try again");
    };
    
    let options2 = [];

    const loadIssueTypeForProject = async (event, projectId, category) => {
        
        setProjectForNewCategory(event, category);
        setIssueType1(oldoptions => [{id: 1, name: 'Loading...'}]);
        await getIssueTypeForProject({projectId})
        .then((res) => {
            let newoptions = res.data.map(r => {
                return {
                    id: r.id,
                    name: r.name
                }
            });
            options2.concat(newoptions);
            console.log(issueType1);
            setIssueType1(oldoptions=> newoptions);
            setissueTypeProjectMap(oldValue => ({
                ...oldValue,
                ...{
                    [projectId]: newoptions
                }
            }))
        }).catch((err) => {
            console.log(err);
        });
        setUseDefaultOptions(false);
    };
    const addCategory = () => {
        setTimeout(() => scrollToBottom(), 100);
        setDraftCategories(draftCategories.concat(categoryTemplate));
        setFilterText('');
    }
    const removeCategory = (index) => {
        const cs = [].concat(draftCategories);
        const removed = cs.splice(index, 1);
        emojis.splice(emojis.indexOf(removed.emoji_id), 1);
        setEmojis(emojis);
        setDraftCategories(cs);
        setFilterText('');
    }
    const filterCategories = () => {
        return draftCategories.map(c => { c.hidden = !c.jira_project_name.toLowerCase().includes(filterText.toLowerCase()); return c; });
    }

    
    const setProjectForNewCategory = (event, category) => {
        const project = jiraProjects1.find((project) => project.key === event.target.value);
        if(project) {
            category.jira_project_id = project.id;
            category.jira_project_name = project.key;
        }
    }

    const duplicateCheckForIssueType = (event, category) => {
        const duplicate = find(draftCategories, (c) => c.jira_project_id === category.jira_project_id && c.jira_issuetype_name === event.target.value && c !== category);
        category.issueError = duplicate ? 'Already present' : undefined;
    }

    const setIssueTypeForNewCategory = (event, category) => {
        const selectedIssueType = issueType1.find((issueType) => issueType.name === event.target.value);
        if(selectedIssueType) {
            category.jira_issuetype_id = selectedIssueType.id;
            category.jira_issuetype_name = selectedIssueType.name;
        }
    }


    const getProjectList = () => {
        let defaultList = [];
        draftCategories.map(c => {
            defaultList.push({
                id: c.jira_project_id,
                key: c.jira_project_name,
            })
            return c;
        })
        setJiraProjects1(defaultList);
        return jiraProjects1;
    }

    const getDynamicProjectList = () => {
        return jiraProjects1;
    }

    
    const defaultIssueType = (projectId) => {
        let options3 = [];
        if(!issueTypeProjectMap[projectId]) {
            options3 = draftCategories.filter(c => {
                return c.jira_project_id === projectId;
            }).map(c => {
                return {
                   id: c.jira_issuetype_id,
                   name: c.jira_issuetype_name,
                }
            });
            setissueTypeProjectMap(oldValue => ({
                ...oldValue,
                [projectId]: options3
            }))
        }
        return issueTypeProjectMap[projectId] || []; 
    }

    const dyanmicIssueType1 = (projectId) => {
        return sortBy(issueTypeProjectMap[projectId], ['name']);
    }


    useEffect(() => {
        const getJiraProjects = async () => {
            let data = await getAllProjects();
            setJiraProjects1(data);
            setJiraProjectHasLoaded(true);
        }
        if(!jiraProjectHasLoaded) {
            getJiraProjects();
        }
   }, /* [] */);

    return (
        <div> 
            {
            !jiraProjectHasLoaded && 
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
          }
          {
            jiraProjectHasLoaded && 
            <form onSubmit={saveCategories}>
                <div className={styles.elementsContainer}>
                    {draftCategories?.length > 5 && <div className={styles.userSearchContainer}>
                        <SearchBar debounceTimeInMs={100} className={styles.searchBar} placeholder="Search category"
                            onChange={(value) => setFilterText(value)} />
                    </div>}
                    <List className={styles.categoriesContainer}>
                        {filterCategories().map((category, index) =>
                            category.hidden ? <></> : <ListItem className={styles.categoryContainer} key={index + category.jira_project_id}>
                                <ListItemText id={category.id} key={index + category.jira_project_id}>
                                    <TextField id={index + category.jira_project_id} key={index + 'jira_project_id'} select required label='Project' error={category?.error?.length > 0} helperText={category.error} className={styles.categoryName} defaultValue={category.jira_project_name} value={category.jira_project_name} variant="standard"
                                        InputProps={{ readOnly: category.readOnly, defaultValue: category.name }}
                                        onChange={(event) => loadIssueTypeForProject(event, category.jira_project_id, category)}
                                        >
                                        { !jiraProjectHasLoaded && (getProjectList()).map((option) => (
                                            <MenuItem key={option.id} value={option.key}>
                                            {option.key}
                                            </MenuItem>
                                        ))}
                                        { jiraProjectHasLoaded && (getDynamicProjectList()).map((option) => (
                                            <MenuItem key={option.id} value={option.key}>
                                            {option.key}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField id={index + category.jira_issuetype_id} key={index + 'jira_issuetype_id'} select required label='Issue' error={category?.issueError?.length > 0} helperText={category.issueError} className={styles.categoryName} defaultValue={category.jira_issuetype_name} value={category.jira_issuetype_name} variant="standard"
                                        InputProps={{ readOnly: category.readOnly, defaultValue: category.name }}
                                        onFocus={(event) => setUseDefaultOptions(false) || loadIssueTypeForProject(event, category.jira_project_id)}
                                        onChange={(e) => {
                                            if (category.readOnly) return;
                                            setIssueTypeForNewCategory(e, category);
                                            duplicateCheckForIssueType(e, category);
                                            setDraftCategories(JSON.parse(JSON.stringify(draftCategories)));
                                        }}
                                        >
                                        {useDefaultOptions && defaultIssueType(category.jira_project_id).map((option) => (
                                            <MenuItem key={option.id} value={option.name}>
                                            {option.name}
                                            </MenuItem>
                                        ))}
                                        {!useDefaultOptions && dyanmicIssueType1(category.jira_project_id).map((option) => (
                                            <MenuItem key={option.id} value={option.name}>
                                            {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </ListItemText>
                                <ListItemSecondaryAction className={styles.jiraSettingsEmojiSelector} key={index}>
                                    <EmojiInput className={styles.categoryEmoji} defaultEmoji={{ name: category.emoji_id }} usedEmojis={emojis}
                                        onChange={(event, value) => {
                                            category.name = category.name === '' ? value.name : category.name;
                                            category.emoji_id = value.name;
                                            setEmojis(emojis.concat(value.name));
                                        }}
                                    />
                                    {!category.readOnly ? <CloseIcon className={styles.removeCategory} onClick={() => removeCategory(index)} edge="end" /> : <div style={{ width: '20px' }}></div>}
                                </ListItemSecondaryAction>
                            </ListItem>)}
                        <div ref={categoriesEndRef} />
                    </List>
                    <div className={styles.addCategory} onClick={addCategory}><AddCircleIcon /> Add Jira Mappings</div>
                </div>
                <Save type='submit' cancelChanges={onCancel}></Save>
            </form>
          }
            
        </div>
            
        );  
};
export default JiraSettings;