//libs
import { configureStore } from '@reduxjs/toolkit';
import { axios } from 'utilities/network/axios';
//reducers
import appConfig from 'redux/appConfig/appConfig';
import metrics from 'redux/metrics/metrics';
import channels from 'redux/channels/channels';
import settings from './settings/settings';

export const store = configureStore({
  reducer: {
    appConfig: appConfig,
    metrics: metrics,
    channels: channels,
    settings: settings
  },
});

axios.interceptors.request.use((config) => {
  const { tokenId } = store.getState().appConfig.user;
  if (tokenId) {
    config.headers.Authorization = `Bearer ${tokenId}`;
  }
  return config;
});
