//libs
import React from 'react';
import GoogleLogin from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
//styles
import styles from './Landing.module.scss';
//actions
import { verifyUserLogin } from 'redux/appConfig/appConfig';
//selectors
import { selectLoginErrorMsg } from 'redux/appConfig/appConfig';
//assests
import appLogo from 'assests/slackdesk.png';

const Landing = () => {
  const dispatch = useDispatch();
  const loginErrorMsg = useSelector(selectLoginErrorMsg);

  const onSuccess = (response) => {
    const { profileObj, tokenId, tokenObj } = response;
    dispatch(verifyUserLogin({ profileObj, tokenId, tokenObj }));
  };

  const onFailure = (e) => {
    console.log('User login failed', e);
  };

  return (
    <div className={styles.container}>
      <div className={styles.centreView}>
        <img alt="logo" src={appLogo} className={styles.appLogo} />
        <div className={styles.titleContainer}>
          <h1 className={styles.appTitle}>Slackdesk</h1>
          <GoogleLogin
            className={styles.googleLoginBtn}
            clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
            buttonText="Sign in with Google"
            onSuccess={onSuccess}
            onFailure={onFailure}
            isSignedIn={true}
            cookiePolicy={'single_host_origin'}
          />
          <p className={styles.errorMsg}>
            {loginErrorMsg ? `${loginErrorMsg} !` : ''}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Landing;
