//libs
import React, { useState } from 'react';
//styles
import styles from './SearchBar.module.scss';
//assests
import { ReactComponent as SearchLogo } from 'assests/search.svg';

let timerId = null;

const SearchBar = ({
  placeholder,
  value,
  onChange,
  className = styles.searchBar,
  debounceTimeInMs = 1500,
}) => {
  const [searchTerm, setSearchTerm] = useState(value);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      onChange(e.target.value);
    }, debounceTimeInMs);
  };

  return (
    <div className={styles.container}>
      <SearchLogo className={styles.searchLogo} />
      <input
        className={className}
        type="text"
        placeholder={placeholder}
        defaultValue={searchTerm}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default SearchBar;
