import React, { useState } from 'react';
import { Alert, Snackbar, Button, AlertTitle } from '@mui/material';
import styles from './EditableComponent.module.scss';
import EditIcon from '@mui/icons-material/Edit';
import { changeEditStatus, selectEditStatus } from 'redux/settings/settings';
import { useDispatch, useSelector } from 'react-redux';
import { EditStatus } from 'utilities/constants/editStatus';

const EditableComponent = ({ render, renderOnEditMode, editable, label }) => {

    const dispatch = useDispatch();
    const [isEditMode, setIsEditMode] = useState(false);
    const editStatus = useSelector(selectEditStatus);

    const onSuccess = (msg) => {
        setIsEditMode(false);
        dispatch(changeEditStatus({ editStatus: { status: EditStatus.SUCCESS, msg: msg } }));
    }
    const onFailure = (msg) => {
        setIsEditMode(false);
        dispatch(changeEditStatus({ editStatus: { status: EditStatus.FAILED, msg: msg } }))
    }
    const onCancel = (msg) => {
        setIsEditMode(false);
    }
    const resetStatus = () => {
        dispatch(changeEditStatus({ editStatus: {} }));
    }

    return (
        <>
            <Snackbar open={editStatus.status === EditStatus.FAILED} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={resetStatus}>
                <Alert severity="error">
                    <AlertTitle>{editStatus?.msg?.title}</AlertTitle>
                    {editStatus?.msg?.text || editStatus?.msg}
                </Alert>
            </Snackbar>
            <Snackbar open={editStatus.status === EditStatus.SUCCESS} autoHideDuration={1000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={resetStatus}>
                <Alert severity="success">
                    {editStatus.msg}
                </Alert>
            </Snackbar>
            {isEditMode ? renderOnEditMode({ onSuccess, onFailure, onCancel }) : (
                <>
                    {
                        editable && (<div className={styles.editContainer}>
                            <Button className={styles.editButton} variant="contained" endIcon={<EditIcon />} onClick={() => setIsEditMode(true)}>
                                {label}
                            </Button>
                        </div>)
                    }
                    {render({ onSuccess, onFailure, onCancel })}
                </>)
            }
        </>
    );
}
export default EditableComponent;