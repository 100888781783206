//libs
import { Button, Grid } from '@mui/material';
import React from 'react';

const Save = ({ saveChanges, cancelChanges, type='button' }) => {

    return (
        <>
            <Grid container spacing={2} sx={{ marginTop: 3 }}>
                <Grid item>
                    <Button type={type} variant="contained" onClick={saveChanges}>Save</Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" onClick={cancelChanges}>Cancel</Button>
                </Grid>
            </Grid>
        </>
    );
};

export default Save;
