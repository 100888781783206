//libs
import { Popover } from '@mui/material';
import React from 'react';
//styles
// import styles from './EmojiPicker.module.scss';

const CustomPopover = ({ renderTrigger, renderPopover }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={{ display: 'inline-block' }}>
            <div onClick={handleClick}>
                {renderTrigger()}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left', }}>
                {renderPopover(handleClose)}
            </Popover>
        </div>
    );
};

export default CustomPopover;
