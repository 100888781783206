import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from 'utilities/network/axios';
//utilities
import { UNASSIGNED } from 'utilities/constants/msgStatus';

const initialState = {
  reports: [],
  messages: [],
  status: UNASSIGNED,
  searchTerm: ''
};

//async actions
export const fetchMsgForChannel = createAsyncThunk(
  'channels/fetchMsgForChannel',
  async ({ channelId, status, searchText, startDate, endDate, user }) => {
    const { data } = await axios.get('/slackdesk/channels/messages', {
      params: {
        channelId,
        status,
        searchText,
        startDate,
        endDate,
        user,
      },
    });
    return data;
  }
);
export const fetchReportsForChannel = createAsyncThunk(
  'channels/fetchReportsForChannel',
  async ({ channelId, startDate, endDate }) => {
    const { data } = await axios.get('/slackdesk/metrics/reports', {
      params: {
        channelId,
        startDate,
        endDate
      },
    });
    return data;
  }
);

export const fetchSettingsForChannel = createAsyncThunk(
  'channels/fetchSettingsForChannel',
  async ({ channelId }) => {
    const { data } = await axios.get('/slackdesk/settings', {
      params: {
        channelId
      },
    });
    return data;
  }
);

export const fetchJiraProjectsForChannel = createAsyncThunk(
  'channels/fetchJiraProjectsForChannel',
  async ({ channelId }) => {
    const { data } = await axios.get('/slackdesk/settings/jira/all-projects', {
      params: {
        channelId
      },
    });
    return data;
  }
)

export const fetchEmojisForChannel = createAsyncThunk(
  'channels/fetchEmojisForChannel',
  async () => {
    const { data } = await axios.get('/slackdesk/settings/emojis');
    return data;
  }
);


//slice configuration
export const channels = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    changeMsgStatus: (state, action) => {
      state.status = action.payload.status;
    },
    changeSearchTerm: (state, action) => {
      state.searchTerm = action.payload.searchTerm;
    },
    changeAgentSettings: (state, action) => {
      state.settings.agents = action.payload.agents;
    },
    changeEmojiSettings: (state, action) => {
      state.settings.ticketStates = action.payload.ticketStates;
    },
    changeCategorySettings: (state, action) => {
      state.settings.categories = action.payload.categories;
    },
    changeRatingSettings: (state, action) => {
      state.settings.ratings = action.payload.ratings;
    },
    changeJiraSettings: (state, action) => {
      state.settings.jiraMappings = action.payload.jiraMappings;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMsgForChannel.fulfilled, (state, action) => {
        state.messages = action.payload;
      })
      .addCase(fetchMsgForChannel.rejected, (state, action) => {
        state.messages = [];
        window.location.reload();
      })
      .addCase(fetchReportsForChannel.fulfilled, (state, action) => {
        state.reports = action.payload;
      })
      .addCase(fetchReportsForChannel.rejected, (state, action) => {
        state.reports = [];
      })
      .addCase(fetchSettingsForChannel.fulfilled, (state, action) => {
        state.settings = action.payload;
      })
      .addCase(fetchSettingsForChannel.rejected, (state, action) => {
        state.settings = [];
      })
      .addCase(fetchEmojisForChannel.fulfilled, (state, action) => {
        state.emojis = action.payload;
      })
      .addCase(fetchEmojisForChannel.rejected, (state, action) => {
        state.emojis = [];
      })
      .addCase(fetchJiraProjectsForChannel.fulfilled, (state, action) => {
        state.jiraProjects = action.payload;
      })
      .addCase(fetchJiraProjectsForChannel.rejected, (state, action) => {
        state.jiraProjects = [];
      });
      
  },
});

// state selectors

export const selectChannelMsgs = (state) => {
  return state.channels.messages;
};

export const selectChannelReports = (state) => {
  return state.channels.reports;
};

export const selectChannelSettings = (state) => {
  return state.channels.settings;
};

export const selectEmojis = (state) => {
  return state.channels.emojis;
};

export const selectMsgStatus = (state) => {
  return state.channels.status;
};

export const selectSearchTerm = (state) => {
  return state.channels.searchTerm;
};

export const selectJiraProjects = (state) => {
  return state.channels.jiraProjects;
};

//actions
export const { changeMsgStatus, changeSearchTerm, changeAgentSettings, changeEmojiSettings, changeCategorySettings, changeRatingSettings, changeJiraSettings } = channels.actions;

export default channels.reducer;
