//libs
import { Box, createTheme, Tab, Tabs, ThemeProvider } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectEmojis } from 'redux/channels/channels';
import { emojiTypes, getAllEmojis } from 'utilities/constants/emojis';
import Avatar from '../avatar/CustomAvatar';
import SearchBar from '../searchBar/SearchBar';
//styles
import styles from './EmojiPicker.module.scss';

const EmojiPicker = ({ onSelect, usedEmojis = [] }) => {

    const theme = createTheme({
        components: {
            MuiTab: {
                styleOverrides: {
                    root: {
                        color: '#000',
                        padding: '7px',
                        minWidth: '30px',
                    }
                },
            }
        }
    });


    const groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    const getEmojisMap = (emojiData) => {
        const emojis = {};
        emojiData?.filter(e => e.name && !usedEmojis.includes(e.name)).map(e => emojis[e.name.toLowerCase()] = e.unified || e.permaLink);
        return emojis;
    }

    const getUnusedEmojis = (emojis) => {
        return emojis.filter(e => !usedEmojis.includes(e.name));
    }

    const emojis = getAllEmojis(useSelector(selectEmojis));
    const emojiGroups = groupBy(emojis, 'category');
    const emojiMap = getEmojisMap(emojis);
    const emojiTabs = emojiTypes.map(e => emojiGroups[e.type]) || [];

    const [selectedEmojiType, setSelectedEmojiType] = React.useState(0);
    const [filteredEmojis, setFilteredEmojis] = React.useState(getUnusedEmojis(emojiTabs[0]));

    const updateFilteredEmojis = (emojis) => {
        setFilteredEmojis(getUnusedEmojis(emojis).slice(0,300));
    }

    const handleEmojiTypeChange = (e, index) => {
        updateFilteredEmojis(emojiTabs[index]);
        setSelectedEmojiType(index);
    };

    const uniq = (values) => {
        var seen = {};
        return values.filter((v) => seen.hasOwnProperty(v.name) ? false : (seen[v.name] = true));
    }
    const filterEmojis = (value) => {
        const matches = emojis.filter(e => (e.name === value))
        .concat(emojis.filter(e => (e.full_name || e.name).includes(value)));
        return uniq(matches).slice(0, 75);
    }

    const getEmojiType = (type, index) => {
        return (<Tab icon={<Avatar styles={{ size: 21 }} name={type.emoji_id || type.name} url={type.permaLink}></Avatar>} key={index} label={type.category} />);
    }

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Box className={styles.emojiPickerContainer}>
                    <Tabs className={styles.emojiTabsContainer}
                        value={selectedEmojiType}
                        onChange={handleEmojiTypeChange}
                    >
                        {
                            emojiTypes.map((e, index) => getEmojiType(e, index))
                        }
                    </Tabs>
                    <div style={{ margin: '10px 15px' }}>
                        <SearchBar debounceTimeInMs={100} className={styles.searchBar} placeholder="Filter Emojis" onChange={(value) => {
                            updateFilteredEmojis(value.length === 0 ? emojiTabs[selectedEmojiType] : filterEmojis(value));
                        }} />
                    </div>
                    <div className={styles.categoryEmojis}>
                        {filteredEmojis.map((e, index) =>
                        (<Avatar
                            key={index}
                            tooltip={e.emoji_id || e.name}
                            styles={{ size: 21, margin: '7px', cursor: 'pointer' }}
                            emojis={emojiMap} name={e.emoji_id || e.name}
                            url={e.permaLink}
                            onSelect={onSelect}></Avatar>)
                        )}
                    </div>
                </Box>
            </ThemeProvider>
        </div>
    );
};

export default EmojiPicker;
