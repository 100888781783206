
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from 'utilities/network/axios';

const initialState = {
    editStatus: {}
};

export const updateUsers = async ({ channelId, users }) => {
    const { data } = await axios.put('/slackdesk/settings/users', 
        users.map(u => { return { email: u.email, is_admin: u.is_admin }; })
    , {
        params: {
            channelId
        }
    });
    return data;
}

export const deleteUser = async ({ channelId, user }) => {
    const { data } = await axios.delete('/slackdesk/settings/user', {
        params: {
            channelId
        },
        data: {
            ...user
        }
    });
    return data;
}

export const updateCategories = async ({ channelId, categories }) => {
    const { data } = await axios.put('/slackdesk/settings/categories', categories, {
        params: {
            channelId
        }
    });
    return data;
}

export const updateJiraMappings = async ({ channelId, jiraMappings }) => {
    const { data } = await axios.put('/slackdesk/settings/jira-mappings', jiraMappings, {
        params: {
            channelId
        }
    });
    return data;
}

export const getIssueTypeForProject = async ({ projectId }) => {
    const { data } = await axios.get(`/slackdesk/settings/jira/issueType/${projectId}`);
    let response = {
        projectId: projectId,
        data: data
    }
    return response;
}

export const getAllProjects = async () => {
    const { data } = await axios.get(`/slackdesk/settings/jira/all-projects`);
    return data;
}

export const updateRatings = async ({ channelId, ratings }) => {
    const { data } = await axios.put('/slackdesk/settings/ratings', 
    ratings.map(u => { return { name: u.name, emoji_id: u.emoji_id || u.name, value: u.value } }), {
        params: {
            channelId
        }
    });
    return data;
}

export const updateTicketStates = async ({ channelId, ticketStates }) => {
    const { data } = await axios.put('/slackdesk/settings/ticketStates', 
    ticketStates.map(u => { return { name: u.name, emoji_id: u.emoji_id || u.name } }), {
        params: {
            channelId
        }
    });
    return data;
}

export const getIssueTypeForProjectThunk = createAsyncThunk(
    '/slackdesk/settings/jira/issueType/projectId',
    async ({projectId}) => {
        const { data } = await axios.get(`/slackdesk/settings/jira/issueType/${projectId}`);
        let response = {
            projectId: projectId,
            data: data
        }
        return response;
    }
  );


export const settings = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        changeEditStatus: (state, action) => {
            state.editStatus = action.payload.editStatus;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getIssueTypeForProjectThunk.fulfilled, (state, action) => {
            ;
            if(!state.issueTypeMap) {
                state.issueTypeMap = {};
            }
            state.issueTypeMap[action.payload.projectId] = action.payload.data;
          })
          .addCase(getIssueTypeForProjectThunk.rejected, (state, action) => {
            state.issueTypeMap = {};
          })
    },
});

export const selectEditStatus = (state) => {
    return state.settings.editStatus || { };
};

export const selectIssueTypeMap = (state) => {
    return state.settings.issueTypeMap || {};
}

export const { changeEditStatus } = settings.actions;
export default settings.reducer;