//libs
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
//styles
import styles from './NavBar.module.scss';
//selectors
import { selectSlackChannels } from 'redux/appConfig/appConfig';
//utilities
import { routes } from 'utilities/routes/routePath';
//assests
import appLogo from 'assests/slackdesk.png';
import LinkLogo from 'assests/link.png';
import { ReactComponent as CategoryLogo } from 'assests/category.svg';

const getNavLogoClass = (isExpanded) => {
  const expandClass = isExpanded ? styles.categoryLogoOpen : '';
  return `${styles.categoryLogo} ${expandClass}`;
};

const getNavLinkClass = (isActive) => {
  return `${styles.navLink} ${isActive ? styles.navLinkActive : ''}`;
};

const getLinkConClass = (isExpanded) => {
  return isExpanded ? {} : { height: '0px' };
};

const NavBar = () => {
  const [channelsNavExpanded, setChannelsNavExpanded] = useState(true);
  const channelList = useSelector(selectSlackChannels);

  const handleChannelsGroupHeaderClick = () => {
    setChannelsNavExpanded(!channelsNavExpanded);
  };

  return (
    <div className={styles.container}>
      <div className={styles.appHeaderCon}>
        <img alt="logo" src={appLogo} className={styles.appLogo} />
        <h1 className={styles.appTitle}>Slackdesk</h1>
      </div>
      <div className={styles.naviContainer}>
        <div
          className={styles.groupHeaderCon}
          onClick={handleChannelsGroupHeaderClick}>
          <CategoryLogo className={getNavLogoClass(channelsNavExpanded)} />
          <h5 className={styles.groupHeaderText}>Channels</h5>
        </div>
        <div
          className={styles.linkChannelsCon}
          style={getLinkConClass(channelsNavExpanded)}>
          {channelList.map(({ id, name }) => {
            return (
              <NavLink
                key={id}
                to={routes.gotoChannelsPage(id)}
                className={getNavLinkClass}>
                # {name}
              </NavLink>
            );
          })}
        </div>
      </div>
      <div className={styles.helpLinkContainer}>
        <a
          href={process.env.REACT_APP_SLACKDESK_HELP_URL}
          className={styles.helpLink}
          target='_blank'
          rel='noreferrer'
          >
          Slackdesk support
          <img src={LinkLogo} alt="-" className={styles.linkLogo} />
        </a>
      </div>
    </div>
  );
};

export default NavBar;
