import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Dialog, DialogTitle, Modal, Typography } from '@mui/material';
import Save from 'components/reusables/save/Save';
import DatePicker from 'components/reusables/datePicker/DatePicker';
import styles from './Settings.module.scss';
import { axios } from 'utilities/network/axios';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'redux/appConfig/appConfig';
import moment from 'moment/moment';
import { useParams } from 'react-router';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';


const ExportCSVParams = ({onCancel}) => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const { channelId } = useParams();
    const [open, setOpen] = useState(false);
    const [errorMsg,setErrorMsg] = useState();
    const [showAlert,setShowAlert] = useState(false);
    const [alertMsg,setAlertMsg] = useState("");
    const [alertStatus,setAlertStatus] = useState("success");
    const [disableSave,setDisableSave] = useState(false);
    const [downloadDataUrl,setDownloadDataUrl] = useState();
    const [fetchedFile,setFetchedFile] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { profileObj = {} } = useSelector(selectUserInfo);
    
    useEffect(()=>{
      if(startDate === undefined || endDate === undefined) {
        setErrorMsg(undefined);
        return;
      };
      var startDateM = moment(startDate);
      var endDateM = moment(endDate);
      if(startDateM.isAfter(endDateM)){
        setErrorMsg("Start date can not be greater than end date");
      } else if(endDateM.diff(startDateM, 'days') > 125) {
        setErrorMsg("Maximum date range is only 125 days")
      } else {
        setErrorMsg("");
      }
    },[startDate,endDate])

    const generateCSVfile = async () => {
        
        let email = profileObj.email;
        if(errorMsg === undefined) {
          setAlertMsg("Please select start date and end date");
          setAlertStatus("error");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
          return;
        } else if(errorMsg !== "") {
          setAlertMsg(errorMsg);
          setAlertStatus("error");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
          return;
        }

        setAlertMsg("Your request is being process");
        setAlertStatus("success");
        setShowAlert(true);
        setDisableSave(true);
        try{
          const { data } = await axios.get('/slackdesk/settings/exportCSV', {
              params: {
                channelId,
                startDate,
                endDate,
                email
              },
            });
          var dataBlob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
          const downloadUrl = URL.createObjectURL(dataBlob);
          console.log(downloadUrl);
          setDownloadDataUrl(downloadUrl);
          setFetchedFile(true);
        } catch(error) {
          console.log("here");
          setFetchedFile(false);
        }
        setShowAlert(false);
        setDisableSave(false);
        handleOpen();
    }   
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

  return (
    <div>
        {showAlert? <Alert severity={alertStatus} onClose={() => {setShowAlert(false)}}>{alertMsg}</Alert>:<></>}
        <div className={styles.dateSelectorCon}>
          <p className={styles.dateLabelFrom}>Filter results from</p>
          <DatePicker value={startDate} onChange={(selectedDate) => setStartDate(selectedDate)} />
          <p className={styles.dateLabelTo}>to</p>
          <DatePicker value={endDate} onChange={(selectedDate) => setEndDate(selectedDate)} />
        </div>
        <div className={styles.errorMsgCon}>
          {errorMsg?<p className={styles.errorMsg}>{errorMsg}</p>:<></>}
        </div>
        <Dialog open={disableSave}>
          <div className={styles.loadingIconCon}>
            <HourglassEmptyIcon fontSize="large"></HourglassEmptyIcon>
          </div>
          <DialogTitle>Your requested data is being prepared</DialogTitle>
        </Dialog>
        <Modal
          ddisableBackdropClick
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {fetchedFile?
              <>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Your CSV file is ready to download.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Please click below link to download.
                </Typography>
                <Button onClick={() => handleClose()} href={downloadDataUrl} download="downloadfile.csv" variant="contained">Download</Button>
              </>
              :
              <>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Failed to fetch file.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Please try again.
                </Typography>
            </>
          }

          </Box>
        </Modal>

        {disableSave?<></>:<Save saveChanges={generateCSVfile} cancelChanges={onCancel}></Save>}
    </div>
  )
}

export default ExportCSVParams