//libs
import React from 'react';
//styles
import styles from './InfoCard.module.scss';

const InfoCard = ({ label, content, subtext, style, handleClick = () => {} }) => {
  return (
    <div className={styles.container} style={style} onClick={handleClick}>
      <div className={styles.infoView}>
        <h6 className={styles.headingLabel}>{label}</h6>
        <div>
          <div className={styles.content}>{content}</div>
          <span className={styles.subtext}>{subtext}</span>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
