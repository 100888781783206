import { DataGrid } from "@mui/x-data-grid";
import Avatar from "components/reusables/avatar/CustomAvatar";
import RenderView from "components/reusables/renderView/RenderView";
import styles from './Reports.module.scss';

const { useSelector } = require("react-redux");
const { selectChannelReports } = require("redux/channels/channels");

const Reports = () => {

    let reports = useSelector(selectChannelReports);
    if (!reports || !(reports?.userReports?.[0])) {
        return (
            <div className={styles.reportCardContainer}>
                <div className={styles.noReports}>No Reports found !</div>
            </div>
        );
    }
    let userReports = reports?.userReports?.map((report, index) => { return { id: index, ...report }; });
    let categoryReports = reports?.categoryReports?.map((report, index) => { return { id: index, ...report }; });
    const columns = [
        {
            field: 'profilePic',
            headerName: '',
            width: 80,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params) => <Avatar name={params.row.username} url={params.row.permaLink} />
        },
        { field: 'username', headerName: 'Name', align: 'left', headerAlign: 'left', width: 200 },
        { field: 'inProgress', headerName: 'InProgress', align: 'center', headerAlign: 'center', width: 150 },
        { field: 'completed', headerName: 'Completed', align: 'center', headerAlign: 'center', width: 150 },
        { field: 'avgCompletionTime', headerName: 'Avg Completion Time', align: 'center', headerAlign: 'center', width: 200 },
        { field: 'avgFirstResponseTime', headerName: 'Avg First Response Time', align: 'center', headerAlign: 'center', width: 200 },
        {
            field: 'rating', headerName: 'Rating', align: 'left', headerAlign: 'left', width: 150, renderCell: (params) => (
                <div>
                    {params.row.rating}
                    <RenderView renderIfTrue={params.row.reviews > 1}>
                        <span className={styles.reviews}>  ({params.row.reviews} reviews)</span>
                    </RenderView>
                </div>
            ),
        }
    ];
    const categoryColumns = [
        {
            field: 'icon',
            headerName: '',
            width: 80,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params) => <Avatar name={params.row.emoji_id} />
        },
        { field: 'name', headerName: 'Category', align: 'left', headerAlign: 'left', width: 200 },
        { field: 'inProgress', headerName: 'InProgress', align: 'center', headerAlign: 'center', width: 150 },
        { field: 'completed', headerName: 'Completed', align: 'center', headerAlign: 'center', width: 150 },
        { field: 'avgCompletionTime', headerName: 'Avg Completion Time', align: 'center', headerAlign: 'center', width: 200 },
        { field: 'avgFirstResponseTime', headerName: 'Avg First Response Time', align: 'center', headerAlign: 'center', width: 200 },
        {
            field: 'rating', headerName: 'Rating', align: 'left', headerAlign: 'left', width: 150, renderCell: (params) => (
                <div>
                    {params.row.rating}
                    <RenderView renderIfTrue={params.row.reviews > 1}>
                        <span className={styles.reviews}>  ({params.row.reviews} reviews)</span>
                    </RenderView>
                </div>
            ),
        }
    ];


    return (
        <div className={styles.reportCardContainer}>
                <div className={styles.userReportsContainer}>
                    <DataGrid
                        rows={userReports}
                        columns={columns}
                        pageSize={100}
                        rowsPerPageOptions={[50, 100, 200, 500]}
                        checkboxSelection={false}
                        disableSelectionOnClick={true}
                    />
                </div>

                <RenderView renderIfTrue={categoryReports?.[0]}>
                        <div className={styles.categoryHeader}>
                            <h2>Top categories</h2>
                        </div>
                        <div className={styles.categoryReportsWrapper}>
                        <DataGrid
                            rows={categoryReports}
                            columns={categoryColumns}
                            pageSize={100}
                            rowsPerPageOptions={[100, 200, 500]}
                            checkboxSelection={false}
                            disableSelectionOnClick={true}
                        />
                        </div>
                </RenderView>
        </div>
    );
}
export default Reports;