//libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import SettingsIcon from '@mui/icons-material/Settings';
//styles
import styles from './Channels.module.scss';
//pages
import Metrics from 'pages/metrics/Metrics';
//components
import DatePicker from 'components/reusables/datePicker/DatePicker';
import SearchBar from 'components/reusables/searchBar/SearchBar';
import Messages from 'components/containers/messages/Messages';
import Dropdown from 'components/reusables/dropdown/Dropdown';
//actions
import { changeIntervalDate, selectSlackChannels, selectUserInfo } from 'redux/appConfig/appConfig';
import { changeSearchTerm, fetchReportsForChannel, fetchSettingsForChannel, fetchJiraProjectsForChannel } from 'redux/channels/channels';
import { fetchMsgForChannel } from 'redux/channels/channels';
//selectors
import { selectIntervalDate } from 'redux/appConfig/appConfig';
import { selectMsgStatus, selectSearchTerm, selectChannelSettings, selectJiraProjects } from 'redux/channels/channels';
//utilities
import { getStatusCode, REPORTS, userTicketList } from 'utilities/constants/msgStatus';
import { UNASSIGNED, MY_TICKETS } from 'utilities/constants/msgStatus';
import { ALL_TICKETS } from 'utilities/constants/msgStatus';
import Reports from 'components/containers/reports/Reports';
import RenderView from 'components/reusables/renderView/RenderView';
import { IconButton, Tooltip } from '@mui/material';
import Settings from 'pages/settings/settings';

const Channels = () => {
  const dispatch = useDispatch();
  const { channelId } = useParams();
  const { startDate, endDate } = useSelector(selectIntervalDate);
  const searchText = useSelector(selectSearchTerm);
  const status = useSelector(selectMsgStatus);
  const [userTicket, setUserTicket] = useState(MY_TICKETS);
  const [openSettings, setOpenSettings] = useState(false);
  const [isReportsMode, setIsReportsMode] = useState(false);
  const channelList = useSelector(selectSlackChannels);
  const isAdmin = channelList.find(c => c.id == channelId)?.isAdmin;

  useEffect(() => {
    const statusCode = getStatusCode(status);
    const user = status === UNASSIGNED ? ALL_TICKETS : userTicket;
    isReportsMode ? dispatch(fetchReportsForChannel({ channelId, startDate, endDate })) : dispatch(
      fetchMsgForChannel({
        channelId,
        searchText,
        startDate,
        endDate,
        user,
        status: statusCode,
      }));
    openSettings && dispatch(fetchSettingsForChannel({ channelId })) && dispatch(fetchJiraProjectsForChannel({ channelId }));

  }, [dispatch, channelId, searchText, startDate, endDate, status, userTicket, isReportsMode, openSettings]);

  const handleStartDateChange = (selectedDate) => {
    dispatch(changeIntervalDate({ startDate: selectedDate, endDate }));
  };

  const handleEndDateChange = (selectedDate) => {
    dispatch(changeIntervalDate({ startDate, endDate: selectedDate }));
  };

  const handleMsgSearch = (searchTerm) => {
    dispatch(changeSearchTerm({ searchTerm }));
  };

  return (
    <div className={styles.container}>
      <RenderView renderIfTrue={openSettings && isAdmin}>
        <Settings
          channelId={channelId}
          settings={useSelector(selectChannelSettings)}
          jiraProjects={useSelector(selectJiraProjects)}
          setOpenSettings={setOpenSettings}
          profile={useSelector(selectUserInfo)}
        />
      </RenderView>
      <div className={styles.topSection}>
        <div className={styles.dropCon}>
          <Dropdown
            value={userTicket}
            options={userTicketList}
            onChange={(value) => {
              setIsReportsMode(value === REPORTS);
              setUserTicket(value);
            }}
          />
          <RenderView renderIfTrue={isAdmin}>
            <Tooltip title="Settings">
              <IconButton onClick={() => setOpenSettings(true)}>
                <SettingsIcon fontSize="medium" ></SettingsIcon>
              </IconButton>
            </Tooltip>
          </RenderView>
        </div>
        <div className={styles.dateSelectorCon}>
          <p className={styles.dateLabelFrom}>Showing results from</p>
          <DatePicker value={startDate} onChange={handleStartDateChange} />
          <p className={styles.dateLabelTo}>to</p>
          <DatePicker value={endDate} onChange={handleEndDateChange} />
        </div>
      </div>
      <div className={styles.bottomSection}>
        <div className={styles.metricsViewCon}>
          <RenderView renderIfTrue={!isReportsMode}>
            <Metrics channelId={channelId} userTicket={userTicket} />
          </RenderView>
        </div>
        {
          !isReportsMode ? (
            <div className={styles.messagesViewCon}>
              <div className={styles.filterCon}>
                <div className={styles.searchBarCon}>
                  <SearchBar
                    placeholder={'search tickets'}
                    value={searchText}
                    debounceTimeInMs={600}
                    onChange={handleMsgSearch}
                  />
                </div>
                <div className={styles.msgStatusCon}>{status}</div>
              </div>
              <div className={styles.messageCon}>
                <Messages />
              </div>
            </div>
          ) : (
            <Reports />
          )
        }
      </div>
    </div>
  );
};

export default Channels;
