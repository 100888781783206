//libs
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
//styles
import 'stylesheets/globalStyles.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//components
import App from 'pages/app/App';
//redux
import { store } from 'redux/store';
//utilites
import { history } from 'utilities/routes/history';

const THEME = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
    fontSize: 22,
    color: '#4c5563'
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          height: 500,
          color: '#4c5563'
        }
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          outline: 'none'
        },
      },
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

