//libs
import React from 'react';
//styles
import styles from './TagBox.module.scss';

const TagBox = ({ title, content }) => {
  return (
    <div className={styles.tagBoxCon}>
      <p className={styles.tagTitle}>{title}</p>
      <p className={styles.tagContent}>{content}</p>
    </div>
  );
};

export default TagBox;
