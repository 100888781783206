//libs
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
//styles
import styles from './UserBar.module.scss';
//components
import RenderView from 'components/reusables/renderView/RenderView';
//actions
import { logoutUser } from 'redux/appConfig/appConfig';
//selectors
import { selectUserInfo } from 'redux/appConfig/appConfig';
import { GoogleLogout } from 'react-google-login';
//utilities
import { history } from 'utilities/routes/history';
import { routes } from 'utilities/routes/routePath';

import profilePic from 'assests/profile.png';

const UserBar = () => {
  const dispatch = useDispatch();
  const { profileObj = {} } = useSelector(selectUserInfo);

  const onLogoutSuccess = () => {
    dispatch(logoutUser());
    history.push(routes.landingPage);
  };
  const onLogoutFailure = (e) => {
    console.log('Logout failure', e);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.leftZone}></div>
        <div className={styles.rightZone}>
          <div className={styles.nameProfileCon}>
            <RenderView renderIfTrue={profileObj.imageUrl}>
              <img
                className={styles.userAvator}
                src={profileObj.imageUrl}
                alt="-"
                onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = profilePic; }}
              />
            </RenderView>
            <RenderView renderIfTrue={!profileObj.imageUrl}>
              <div className={styles.userNameCircle}>
                {profileObj.name ? profileObj.name[0] : null}
              </div>
            </RenderView>
            <p className={styles.userName}>Hi {profileObj.name}</p>
          </div>
          <GoogleLogout
            clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
            render={(renderProps) => (
              <button
                className={styles.logoutBtn}
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}>
                Logout
              </button>
            )}
            onLogoutSuccess={onLogoutSuccess}
            onFailure={onLogoutFailure}></GoogleLogout>
        </div>
      </div>
    </div>
  );
};

export default UserBar;
