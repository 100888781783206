import React, { useState } from 'react';
import { ListItemAvatar, ListItemText, List, ListItem } from '@mui/material';
import Save from 'components/reusables/save/Save';
import EmojiInput from 'components/reusables/emojiInput/emojiInput';
import CustomAvatar from 'components/reusables/avatar/CustomAvatar';
import { useDispatch } from 'react-redux';
import styles from './Settings.module.scss';

const EmojiSettings = ({
    channelId,
    onSuccess,
    onFailure,
    onCancel,
    elements = [],
    usedEmojis = [],
    columns = [{ name: 'name' }],
    isEditMode,
    onSave,
    updateState,
    showHeaders = false,
    label = "" }) => {

    const dispatch = useDispatch();
    let draftElements = JSON.parse(JSON.stringify(elements));
    const [emojis, setEmojis] = useState(usedEmojis);

    const replaceEmojis = (from, to) => {
        const index = emojis.indexOf(from);
        index > -1 && emojis.splice(index, 1);
        emojis.push(to);
        setEmojis(emojis);
    }

    const saveElements = async () => {
        await onSave({
            channelId: channelId,
            [label]: draftElements
        }).then((res) => {
            onSuccess("Updated emojis successfully");
            if (res) {
                dispatch(updateState({ [label]: res }));
            }
        }).catch((err) => {
            onFailure("Failed to update emojis");
        });
    };

    return (
        <>
        <div className={styles.elementsContainer}>
            <List className={styles.elementList}>

                {showHeaders && <ListItem key={'elementsHeader'}>
                    {!isEditMode && <ListItemAvatar className={styles.elementEmoji} />}
                    {columns.map(col => <ListItemText style={{ ...col.styles, fontWeight: 'bold' }} className={styles.elementName} key={col.name} primary={col.label || col.name} />)}
                    {isEditMode && <div style={{ width: '70px' }} />}
                </ListItem>}

                {draftElements.map((element, index) => {
                    return (
                        <ListItem key={element.emoji_id} style={{ padding: '12px 35px 12px 15px' }}>
                            {
                                !isEditMode && <ListItemAvatar className={styles.elementEmoji}>
                                    <CustomAvatar name={element.emoji_id} url={element.image_url} />
                                </ListItemAvatar>
                            }
                            {
                                columns.map(col => <ListItemText style={col.styles} className={styles.elementName} key={element[col.name]} primary={element[col.name]} />)
                            }
                            {
                                isEditMode && <EmojiInput usedEmojis={emojis}
                                    defaultEmoji={{ name: element.emoji_id }}
                                    onChange={(event, value) => { 
                                        replaceEmojis(draftElements[index].emoji_id, value.name);
                                        draftElements[index] = { ...draftElements[index], emoji_id: value.name }; 
                                    }}
                                />
                            }
                        </ListItem>
                    )
                })}
            </List>
            </div>
            {
                isEditMode && <Save saveChanges={saveElements} cancelChanges={onCancel}></Save>
            }
        </>
    );
};
export default EmojiSettings;