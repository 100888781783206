import axiosPkg from 'axios';

let serverURL;

if (process.env.NODE_ENV === 'development') {
  serverURL =
    process.env.REACT_APP_SERVER_URL ??
    console.log('Server port not initiliazed in .env');
} else {
  serverURL = '/';
}

export const axios = axiosPkg.create({
  baseURL: serverURL,
  headers: { 'Content-Type': 'application/json' },
});
