//libs
import { createTheme, ThemeProvider, Tooltip } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectEmojis } from 'redux/channels/channels';
import { getAllEmojis } from 'utilities/constants/emojis';
//styles
import classes from './Avatar.module.scss';
import profilePic from 'assests/profile.png';

const CustomAvatar = ({ emojis = {}, name, url, styles = {}, onSelect = () => { }, tooltip = '' }) => {
    
    !styles.size && (styles.size = 35);
    const style = { ...styles, width: styles.size + 'px', height: styles.size + 'px', fontSize: styles.size + 'px' };

    const theme = createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        fontSize: (styles.size / 2) + 'px'
                    }
                },
            }
        }
    });

    const getEmojisMap = (emojiData) => {
        const emojis = {};
        emojiData?.filter(e => e.name).map(e => emojis[e.name.toLowerCase()] = e.unified || e.permaLink);
        return emojis;
    }

    const handleOnClick = (event) => {
        onSelect(event, { name, url });
    }

    let allEmojis = getAllEmojis(useSelector(selectEmojis));
    Object.keys(emojis).length === 0 && (emojis = getEmojisMap(allEmojis));
    name = name?.toLowerCase();
    const unicode = name && emojis[name];
    if (!url && unicode?.startsWith("http")) {
        url = unicode;
    }

    return (
        <ThemeProvider theme={theme}>
            <div onClick={handleOnClick} className={classes.avatarContainer} style={style}>
                <Tooltip title={tooltip} followCursor={true}>
                    {url ?
                        <img className={classes.userAvatar} src={url} alt=""
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = profilePic;
                            }}
                        />
                        : name && emojis && emojis[name] ?
                            (<div className={classes.emoji}>{String.fromCodePoint(...(emojis[name]?.split("-")?.map(c => "0x" + c)))}</div>)
                            : <div style={{ height: style.size + 'px' }} className={classes.userNameCircle}>{name?.toUpperCase()?.[0] || 'U'}</div>}
                </Tooltip>
            </div>
        </ThemeProvider>
    );
};

export default CustomAvatar;
