//libs
import React from 'react';
import { useSelector } from 'react-redux';
//styles
import styles from './Messages.module.scss';
//components
import MessageCard from 'components/containers/messageCard/MessageCard';
//selectors
import { selectChannelMsgs } from 'redux/channels/channels';

const Messages = () => {
  const messages = useSelector(selectChannelMsgs);

  if (!messages.length) {
    return (
      <div className={styles.containerNotFound}>
        <h5 className={styles.notfound}>No tickets found !</h5>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {messages.map((msg) => {
        return <MessageCard key={msg.id} info={msg} />;
      })}
    </div>
  );
};

export default Messages;
