//libs
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//styles
import styles from './Metrics.module.scss';
//components
import InfoCard from 'components/containers/infoCard/InfoCard';
//actions
import { fetchMetricsForChannel } from 'redux/metrics/metrics';
import { changeMsgStatus } from 'redux/channels/channels';
//selectors
import { selectIntervalDate } from 'redux/appConfig/appConfig';
import { selectUserChannelMetrics } from 'redux/metrics/metrics';
import { selectMsgStatus } from 'redux/channels/channels';
//utilities
import { UNASSIGNED, INPROGRESS } from 'utilities/constants/msgStatus';
import { COMPLETED } from 'utilities/constants/msgStatus';

const Metrics = ({ channelId, userTicket }) => {
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector(selectIntervalDate);
  const userMetrics = useSelector(selectUserChannelMetrics);
  const msgStatus = useSelector(selectMsgStatus);

  useEffect(() => {
    if (channelId) {
      dispatch(
        fetchMetricsForChannel({
          startDate,
          endDate,
          channelId,
          user: userTicket,
        })
      );
    }
  }, [channelId, startDate, endDate, userTicket, dispatch]);

  const handleInfoCardClick = (status) => {
    dispatch(changeMsgStatus({ status }));
  };

  const getInfoCardStyle = (status, msgStatus) => {
    return {
      cursor: 'pointer',
      border: status === msgStatus ? '1px solid #0e4b7f' : '1px solid white',
    };
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapCon}>
        <InfoCard
          style={getInfoCardStyle(UNASSIGNED, msgStatus)}
          label="Unassigned"
          content={userMetrics.unAssigned}
          handleClick={() => handleInfoCardClick(UNASSIGNED)}
        />
        <InfoCard
          style={getInfoCardStyle(INPROGRESS, msgStatus)}
          label="In progress"
          content={userMetrics.inProgress}
          handleClick={() => handleInfoCardClick(INPROGRESS)}
        />
        <InfoCard
          style={getInfoCardStyle(COMPLETED, msgStatus)}
          label="Completed"
          content={userMetrics.completed}
          handleClick={() => handleInfoCardClick(COMPLETED)}
        />
        <InfoCard
          style={{ width: '220px' }}
          label="Avg completion time"
          content={userMetrics.avgCompletionTime}
        />
        <InfoCard
          style={{ width: '220px' }}
          label="Avg first response time"
          content={userMetrics.avgFirstResponseTime}
        />
        <InfoCard
          label="Rating"
          style={{ width: '180px' }}
          content={userMetrics.rating}
          subtext={userMetrics.reviews > 1 ? '('+ userMetrics.reviews+' reviews)' : ''}
        />
      </div>
    </div>
  );
};

export default Metrics;
